export const searchBars = async (query) => {
  const response = await fetch("https://searchbars2-pu4h6t7fxa-uc.a.run.app", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  });
  return await response.json();
};
