import { useLocation } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Transcript from "./Transcript";
import utilities from "../../utilities";
import ProgressBar from "./ProgressBar";
import { ErrorContent } from "../../ErrorPage";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { ReactComponent as NoResultsSvg } from "../../no-results.svg";
import useLargeScreen from "../../useLargeScreen";

const useUtilities = makeStyles(utilities);
const useStyles = makeStyles((theme) =>
  createStyles({
    imgWrapper: {
      alignSelf: "center",
    },
    transcript: {
      overflowY: "auto",
      padding: "0.5rem",
      maxHeight: "100%",
      "& > * + *": {
        marginTop: "0.5rem",
      },
    },
    hit: {
      borderRadius: theme.shape.borderRadius,
      cursor: "pointer",
      "&.active, &:hover": {
        background: theme.palette.action.hover,
        boxShadow: "inset 0 0 0 1px #e0e0e0",
      },
      "& > *": {
        padding: theme.spacing(1),
      },
    },
    sidebar: {
      maxHeight: "75vh",
      position: "sticky",
      top: "2rem",
    },
  })
);

const ErrorFetching = () => (
  <Grid container spacing={1} style={{ marginTop: "3rem" }}>
    <Grid item xs={12} md={8}>
      <Container maxWidth="xs">
        <ErrorContent />
      </Container>
    </Grid>
  </Grid>
);

const NoResults = (metadata) => (
  <>
    <QueryStats {...metadata} />
    <Grid container spacing={1} style={{ marginTop: "3rem" }}>
      <Grid item xs={12} md={8}>
        <Container maxWidth="xs">
          <Typography
            component="h1"
            variant="body1"
            align="center"
            gutterBottom
          >
            <b>Looks like there is nothing here.</b>
          </Typography>
          <Box mt={2} />
          <NoResultsSvg width="100%" />
        </Container>
      </Grid>
    </Grid>
  </>
);

const QueryStats = ({ total, videos }) => (
  <Typography variant="caption" color="textSecondary" display="block">
    Around {total} results in {videos} videos
  </Typography>
);

const parseResponse = (response) => {
  const results = response.videos;
  const resultsMetaData = {
    total: response.total,
    videos: results.length,
  };
  return { resultsMetaData, results };
};

const ResultsSection = ({ status, data }) => {
  const classes = useStyles();
  const utilityClasses = useUtilities();
  const { search } = useLocation();
  const largeScreen = useLargeScreen();
  const query = new URLSearchParams(search).get("query");
  const [bars, setBars] = React.useState(null);
  const [selectedVideoId, setSelectedVideoId] = React.useState(null);
  const [selectedTitle, setSelectedTitle] = React.useState(null);

  useEffect(() => {
    setBars(null);
    setSelectedVideoId(null);
    setSelectedTitle(null);
  }, [query]);

  if (status === "error") return <ErrorFetching />;
  if (!data) return null;

  const { resultsMetaData, results } = parseResponse(data);

  if (results.length === 0) return <NoResults {...resultsMetaData} />;

  return (
    <>
      <ProgressBar show={status === "loading"} />
      <QueryStats {...resultsMetaData} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} className={utilityClasses.flow}>
          {results.map((video) => {
            const { videoId, title } = video;
            const count = video.barsCount;
            const children = video.bars;
            return (
              <Fragment key={videoId}>
                <Grid
                  container
                  onKeyPress={(evt) => {
                    if (evt.code === "Enter") {
                      setSelectedVideoId(videoId);
                      setSelectedTitle(title);
                      setBars(children);
                    }
                  }}
                  tabIndex={0}
                  className={clsx(classes.hit, {
                    active: videoId === selectedVideoId,
                  })}
                  onClick={() => {
                    setSelectedVideoId(videoId);
                    setSelectedTitle(title);
                    setBars(children);
                  }}
                >
                  <Grid item xs={12} sm={3} className={classes.imgWrapper}>
                    <img
                      src={`https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`}
                      alt="Thumbnail"
                      style={{ borderRadius: 4 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="body1" gutterBottom>
                      <b>{title}</b>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="block"
                      gutterBottom
                    >
                      About {count} times in this video
                    </Typography>
                  </Grid>
                  {bars && !largeScreen && videoId === selectedVideoId ? (
                    <Grid item xs={12} style={{ maxHeight: "19.5rem" }}>
                      <Transcript
                        videoId={selectedVideoId}
                        title={selectedTitle}
                        bars={bars}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {bars && largeScreen && (
          <Grid item md={4} className={classes.sidebar}>
            <Transcript
              videoId={selectedVideoId}
              title={selectedTitle}
              bars={bars}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ResultsSection;
