import logo from "./logo.png";
import React from "react";
import { useTheme } from "@material-ui/core";

const Logo = () => {
  const theme = useTheme();
  const styles = theme.palette.type === "dark" ? {} : { filter: "invert(0.8)" };
  return <img src={logo} alt="logo" style={styles} />;
};

export default Logo;
