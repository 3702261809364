import { useLocation } from "react-router-dom";
import { searchBars } from "../../api";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import { useQuery } from "react-query";
import Header from "./Header";
import utilities from "../../utilities";
import ResultsSection from "./ResultsSection";
import Box from "@material-ui/core/Box";
import ProgressBar from "./ProgressBar";
import React from "react";

const useUtilities = makeStyles(utilities);

const Results = () => {
  const utilityClasses = useUtilities();
  const { search } = useLocation();
  const query = new URLSearchParams(search).get("query");
  const queryResults = useQuery(["results", query], () => searchBars(query));

  return (
    <>
      <Box height="0.5rem" />
      <ProgressBar show={queryResults.status === "loading"} />
      <Container maxWidth="lg" className={utilityClasses.flow}>
        <Header />
        <ResultsSection {...queryResults} />
      </Container>
    </>
  );
};

export default Results;
