import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import SearchInput from "./SearchInput";
import utilities from "../../utilities";
import Socials from "../../Socials";
import React from "react";
import Logo from "../../Logo";

const useUtilities = makeStyles(utilities);

const Search = () => {
  const utilityClasses = useUtilities();

  return (
    <Container
      component="main"
      maxWidth={false}
      className={utilityClasses.fullHeight}
    >
      <Grid
        container
        direction="column"
        className={clsx(utilityClasses.fullHeight)}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid
          item
          style={{ flexGrow: 1 }}
          container
          alignItems="flex-start"
          justify="flex-end"
        >
          <Socials />
        </Grid>
        <Grid item style={{ flexGrow: 2, maxWidth: 600 }}>
          <Logo />
          <SearchInput />
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
      </Grid>
    </Container>
  );
};

export default Search;
