import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import SearchInput from "../search/SearchInput";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useLargeScreen, { useXSmallScreen } from "../../useLargeScreen";
import Socials from "../../Socials";
import Logo from "../../Logo";

const Header = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search).get("query");
  const largeScreen = useLargeScreen();
  const smallScreen = useXSmallScreen();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = query;
  }, [query]);

  return (
    <Grid
      component="header"
      container
      alignItems="center"
      justify={"space-between"}
      spacing={1}
      style={smallScreen ? { marginTop: "25px" } : {}}
    >
      <Grid item xs={12} md={8} container alignItems="center" justify="center">
        <Grid item xs={12} md={2} style={{ minWidth: 100, maxWidth: 150 }}>
          <Link href="/" display="block" style={{ marginRight: "0.5rem" }}>
            <Logo />
          </Link>
        </Grid>
        <Grid item xs={12} md={10} style={{ flexGrow: 1 }}>
          <SearchInput ref={inputRef} />
        </Grid>
      </Grid>
      <Grid
        item
        style={largeScreen ? {} : { position: "absolute", top: 0, right: 8 }}
      >
        <Socials />
      </Grid>
    </Grid>
  );
};

export default Header;
