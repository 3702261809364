import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Fire from "@material-ui/icons/Whatshot";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";
import words from "../../words.json";

const randomWord = () => {
  const randomIndex = Math.floor(Math.random() * (words.length - 1));
  return words[randomIndex];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        boxShadow: theme.shadows[4],
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

const SearchInput = React.forwardRef(({ defaultValue }, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();
    const searchTerm = event.target.search.value;
    if (searchTerm) {
      search(searchTerm);
    }
  };

  const randomSearch = () => {
    search(randomWord());
  };

  const search = (searchTerm) => {
    navigate(`/results?query=${searchTerm}`);
  };

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      className={classes.root}
      autoComplete="off"
      variant="outlined"
    >
      <InputBase
        fullWidth
        inputRef={ref}
        className={classes.input}
        placeholder="Search For Harry Mack Bars"
        inputProps={{ "aria-label": "search harry mack", maxLength: "100" }}
        defaultValue={defaultValue}
        name="search"
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <Tooltip title="Random Word" placement="top">
        <IconButton
          color="inherit"
          className={classes.iconButton}
          aria-label="feeling lucky"
          onClick={randomSearch}
        >
          <Fire />
        </IconButton>
      </Tooltip>
    </Paper>
  );
});

export default SearchInput;
