import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      padding: theme.spacing(1),
    },
    transcript: {
      overflowY: "auto",
      padding: theme.spacing(1),
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
  })
);

const youtubeLink = (videoId, time) =>
  `https://youtu.be/${videoId}?t=${Math.trunc(parseInt(time))}`;

const Transcript = ({ videoId, title, bars }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <div className={classes.header}>
        <Typography variant="body2">
          <b>Transcript</b>
        </Typography>
        <Link
          variant="body2"
          href={youtubeLink(videoId, 0)}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          <Typography variant="caption" display="block">
            {title}
          </Typography>
        </Link>
      </div>
      <Divider variant="fullWidth" />
      <div className={classes.transcript}>
        {bars.map(({ time, text }) => (
          <div key={time}>
            <Link
              variant="body2"
              href={youtubeLink(videoId, time)}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              <b>{secondsToTime(time)}</b> - {text}
            </Link>
          </div>
        ))}
      </div>
    </Card>
  );
};

const doubleDigit = (n) => (n < 10 ? `0${n}` : n);
const secondsToTime = (t) => {
  const time = parseInt(t, 10);
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = time - hours * 3600 - minutes * 60;
  return `${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(
    seconds
  )}`;
};

export default Transcript;
