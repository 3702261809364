import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import LightModeIcon from "@material-ui/icons/Brightness5";
import DarkModeIcon from "@material-ui/icons/Brightness3";
import Tooltip from "@material-ui/core/Tooltip";
import { useDarkMode } from "./DarkModeContext";
import useLargeScreen, { useXSmallScreen } from "./useLargeScreen";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
  })
);

const Socials = () => {
  const [open, setOpen] = useState(false);
  const [darkMode, toggleDarkMode] = useDarkMode();
  const classes = useStyles();
  const largeScreen = useLargeScreen();
  const smallScreen = useXSmallScreen();
  const fontSize = largeScreen ? "medium" : "small";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="youtube"
        href="https://www.youtube.com/c/redhwannacef"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YouTubeIcon fontSize={fontSize} />
      </IconButton>
      <IconButton
        aria-label="twitter"
        href="https://twitter.com/redhwannacef"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon fontSize={fontSize} />
      </IconButton>
      <Tooltip title={darkMode ? "Light Mode" : "Dark Mode"}>
        <IconButton aria-label="toggle dark mode" onClick={toggleDarkMode}>
          {darkMode ? (
            <DarkModeIcon fontSize={fontSize} />
          ) : (
            <LightModeIcon fontSize={fontSize} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="About">
        <IconButton aria-label="about" onClick={handleClickOpen}>
          <InfoIcon fontSize={fontSize} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={smallScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Harry Mack Bars
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Hey 👋🏼</DialogContentText>
          <DialogContentText>
            Harrymackbars is a search engine for all of Harry Macks raps. This
            is a little side project I thought would be cool to build.
          </DialogContentText>
          <DialogContentText>
            All the transcripts here are taken straight from YouTube, which
            means some of the results might come from other people in the
            videos. I think that's okay, it would take much longer to figure
            that one out!
          </DialogContentText>
          <DialogContentText>
            If you have any feedback, let me know! I have linked my twitter on
            the top of the site. I hope you enjoy it 🙂
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Socials;
