import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useLargeScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
};

export const useXSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xs"));
};

export default useLargeScreen;
