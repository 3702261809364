import createStyles from "@material-ui/core/styles/createStyles";

const utilities = (theme) =>
  createStyles({
    flow: {
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
    fullHeight: {
      height: "100%",
    },
  });

export default utilities;
