import "./utilities";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import Search from "./pages/search";
import Results from "./pages/results";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorPage from "./ErrorPage";
import { useMemo } from "react";
import useDarkMode, { DarkModeProvider } from "./DarkModeContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <DarkModeProvider>
    <Content />
  </DarkModeProvider>
);

const Content = () => {
  const [prefersDarkMode] = useDarkMode();
  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createMuiTheme({
          palette: {
            type: prefersDarkMode ? "dark" : "light",
          },
        })
      ),
    [prefersDarkMode]
  );

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Search />} />
                <Route path="results/" element={<Results />} />
              </Routes>
            </BrowserRouter>
          </ErrorBoundary>
        </QueryClientProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
