import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ReactComponent as Void } from "./void.svg";
import utilities from "./utilities";
import Socials from "./Socials";
import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useUtilities = makeStyles(utilities);

export const ErrorContent = () => (
  <>
    <Typography component="h1" variant="body1" align="center" gutterBottom>
      <b>Oops, something went wrong.</b>
    </Typography>
    <Typography variant="body1" align="center">
      <b>
        Try <Link href="">refreshing</Link> the page.
      </b>
    </Typography>
    <Box mt={2} />
    <Void width="100%" />
  </>
);

const ErrorPage = () => {
  const utilityClasses = useUtilities();

  return (
    <Grid
      component="main"
      container
      direction="column"
      className={utilityClasses.fullHeight}
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item
        style={{ flexGrow: 1 }}
        container
        alignItems="flex-start"
        justify="flex-end"
      >
        <Socials />
      </Grid>
      <Grid item style={{ flexGrow: 2 }}>
        <Container maxWidth="xs">
          <ErrorContent />
        </Container>
      </Grid>
      <Grid item style={{ flexGrow: 1 }} />
    </Grid>
  );
};

export default ErrorPage;
