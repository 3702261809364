import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      minHeight: theme.spacing(1),
      position: "fixed",
      top: 0,
      left: 0,
    },
    bar: {
      backgroundColor: grey[700],
    },
    bar1Buffer: {
      backgroundColor: grey[300],
    },
  })
);

const ProgressBar = ({ show = false }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root} style={{ margin: 0 }}>
      <Fade
        in={show}
        style={{
          transitionDelay: show ? "800ms" : "0ms",
        }}
        unmountOnExit
      >
        <LinearProgress
          variant="indeterminate"
          value={progress}
          classes={{
            bar: classes.bar,
            indeterminate: classes.bar1Buffer,
          }}
        />
      </Fade>
    </div>
  );
};

export default ProgressBar;
